export default class FrontPageService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/front-page/sliders.component' /* webpackChunkName: "scripts/sliders.component" */
                ).then(({ default: SlidersComponent }) => {
                    new SlidersComponent()
                })

                import(
                    '@scripts/components/front-page/strate-interactive-map.component' /* webpackChunkName: "scripts/strate-interactive-map.component" */
                ).then(({ default: StrateInteractiveMapComponent }) => {
                    new StrateInteractiveMapComponent()
                })

                import(
                    '@scripts/components/front-page/strate-booking.component' /* webpackChunkName: "scripts/strate-booking.component" */
                ).then(({ default: StrateBookingComponent }) => {
                    new StrateBookingComponent()
                })

                import(
                    '@scripts/components/front-page/strate-social-wall.component' /* webpackChunkName: "scripts/strate-social-wall.component" */
                ).then(({ default: StrateSocialWallComponent }) => {
                    new StrateSocialWallComponent()
                })

                import(
                    '@scripts/components/front-page/animations.component' /* webpackChunkName: "scripts/animations.component" */
                ).then(({ default: AnimationsComponent }) => {
                    new AnimationsComponent()
                })
            } else {
                import(
                    '@scripts/components/front-page/strate-interactive-map-eco.component' /* webpackChunkName: "scripts/strate-interactive-map-eco.component" */
                ).then(({ default: StrateInteractiveMapEcoComponent }) => {
                    new StrateInteractiveMapEcoComponent()
                })
            }

            import(
                '@scripts/components/front-page/strate-agenda.component' /* webpackChunkName: "scripts/strate-agenda.component" */
            ).then(({ default: StrateAgendaComponent }) => {
                new StrateAgendaComponent()
            })
        }
    }
}
